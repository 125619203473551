@tailwind base;
@tailwind components;
@tailwind utilities;
*, *::before, *::after{
  box-sizing: border-box;
}
@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700);
body,h1,h2,h3,h4,p,figure,blockquote,dl,dd{
  font-family: 'Open Sans';
  font-weight: 700;
  margin: 0;
}


body {
  height: 100vh;
  /* overflow: hidden; */
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.emoji{
  font-size: 8rem;
}