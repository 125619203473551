.app{
  display: grid;
  grid-template-columns: 1fr min(99ch, 100%) 1fr;
  min-block-size: 100vh;
  overflow-x: hidden;
}
.app > *{
  grid-column: 2;
}
.full-bleed {
  width:100%;
  grid-column: 1 / 4;
}
.answers-row {
  width:100%;
}
 
.contact{
  background-color: ;
  /* padding-left: 50px;
  padding-right: 50px; */
}

@media screen and (max-width: 980px){
  .app{
    padding: 0 1rem;
  }
}